// Import the assets required for the cover page

import '../../scss/theme/default/cover.scss'
import '../../modules/bootstrap'
import '../../modules/font-awesome'

$(function () {
    if (location.hash) {
        $("form").each(function () {
            var action = $(this).attr("action");
            $(this).attr("action", action + location.hash);
        })
    }

    $("form").on('submit', function() {
        $(":submit", this).addClass('disabled btn-disabled')
    })
})